<template>
  <div class="GoogleMapComponent animatedBox">
    <div class="container fluid">
      <div class="card always-shadow p-1">
        <form
          class="d-flex"
          @submit.prevent="
            (e) => {
              geocode(address);
            }
          "
        >
          <div class="expanded pr-1">
            <fd-input
              v-model="address"
              :placeholder="`Search an address`"
            ></fd-input>
          </div>
          <button class="btn main mr-1" type="submit">Search</button>
          <button class="btn secondary" type="button" @click="getLocation">
            <i class="fas fa-map-marker-alt"></i>
          </button>
        </form>

        <br />

        <div class="scaled-container">
          <div id="map">
            <gmaps-map
              :options="mapOptions"
              @mounted="initMapCallBack"
              @click="onMapClick"
            >
              <gmaps-marker :options="markerOptions" />
            </gmaps-map>
          </div>
        </div>

        <form
          @submit.prevent="
            (e) => {
              reversedGeocode(latlng);
            }
          "
          class="d-flex justify-content-between align-items-end mt-2 mxn-1"
        >
          <div class="d-flex">
            <div class="d-block px-1">
              <label>Latitude</label>
              <fd-input
                v-model="latlng.lat"
                :placeholder="`Latitude`"
                type="number"
              ></fd-input>
            </div>

            <div class="d-block px-1">
              <label>Longitude</label>
              <fd-input
                v-model="latlng.lng"
                :placeholder="`Longitude`"
                type="number"
              ></fd-input>
            </div>
          </div>

          <div class="submitButton px-1">
            <button class="btn main" type="submit">Go</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import Axios from "axios";
import { gmapsMap, gmapsMarker } from "x5-gmaps";

export default {
  name: "GoogleMapComponent",
  components: { gmapsMap, gmapsMarker },
  props: {
    initialAddress: {
      type: String,
      default: null
    },
    initialGeometry: {
      type: Object,
      default: () => {
        /* 
          {
            lat: number,
            lng: number
          }
        */
        return null;
      }
    }
  },
  data() {
    return {
      address: "",
      latlng: {
        lat: "",
        lng: ""
      },
      mapOptions: {
        draggableCursor: "pointer",
        center: { lat: -34.397, lng: 150.644 },
        zoom: 16
      },
      markerOptions: {
        position: { lat: -34.397, lng: 150.644 }
      },
      mapInstance: null
    };
  },
  async mounted() {
    if (this.initialAddress != null) {
      await this.geocode(this.initialAddress);
    } else if (this.initialGeometry != null) {
      await this.reversedGeocode(this.initialGeometry);
    } else {
      this.$nextTick(async () => {
        await this.getLocation();
      });
    }
  },
  methods: {
    async geocode(address) {
      try {
        const axiosInstance = Axios.create();
        delete axiosInstance.defaults.headers.common["Authorization"];

        const getApi = await axiosInstance.get(
          `https://maps.googleapis.com/maps/api/geocode/json?address=${address}&key=${this.$googleApiKey}`
        );

        if (getApi.data.results.length > 0) {
          this.moveToMap({
            latlng: getApi.data.results[0].geometry.location,
            address: getApi.data.results[0].formatted_address
          });
        }

        return null;
      } catch (error) {
        throw error;
      }
    },
    async reversedGeocode(latlng) {
      if (latlng != null) {
        try {
          const axiosInstance = Axios.create();
          delete axiosInstance.defaults.headers.common["Authorization"];

          const getApi = await axiosInstance.get(
            `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latlng.lat},${latlng.lng}&language=en&key=${this.$googleApiKey}`
          );

          if (getApi.data.results.length > 0) {
            this.moveToMap({
              latlng: getApi.data.results[0].geometry.location,
              address: getApi.data.results[0].formatted_address
            });
          }

          return null;
        } catch (error) {
          throw error;
        }
      }
    },
    initMapCallBack(map) {
      this.mapInstance = map;
    },
    async onMapClick(val) {
      const latLng = {
        lat: val.latLng.lat(),
        lng: val.latLng.lng()
      };

      await this.reversedGeocode(latLng);
    },
    async getLocation() {
      if (navigator.geolocation) {
        const p = await new Promise((res, rej) => {
          navigator.geolocation.getCurrentPosition((position) => {
            res({
              lat: position.coords.latitude,
              lng: position.coords.longitude
            });
          });
        });

        await this.reversedGeocode(p);
      } else {
        console.log("Test");
      }
    },
    moveToMap({ latlng, address }) {
      const latLngObj = new google.maps.LatLng(latlng.lat, latlng.lng);
      this.mapInstance.panTo(latLngObj);

      this.markerOptions.position = {
        lat: latlng.lat,
        lng: latlng.lng
      };

      this.latlng = {
        lat: latlng.lat,
        lng: latlng.lng
      };

      this.address = address || this.address;

      this.$emit("mapUpdate", {
        geometry: this.latlng,
        address: this.address
      });
    }
  }
};
</script>

<style lang="scss">
.GoogleMapComponent {
  .card {
    &.always-shadow {
      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1), 0 4px 8px rgba(0, 0, 0, 0.1) !important;
    }

    .scaled-container {
      position: relative;

      &:before {
        content: "";
        display: block;
        padding-top: calc(7 / 16 * 100%);
      }

      #map {
        border: 1px solid #ddd;
        position: absolute;
        height: 100%;
        width: 100%;
        bottom: 0;
        left: 0;
        top: 0;
      }
    }
    .expanded {
      flex: 1;
    }
  }

  @media #{$breakpoint-down-sm} {
    .card {
      .scaled-container {
        &:before {
          padding-top: calc(10 / 16 * 100%);
        }
      }
    }
  }
}
</style>
